import "./App.scss";

import React, { Component, useState } from "react";

import View from "./View.js";



export default class App extends Component {

  render() {
    return (
      <div>
        <View />
      </div>
    );
  }
}